import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, toHandlerKey as _toHandlerKey, withModifiers as _withModifiers, mergeProps as _mergeProps, createBlock as _createBlock, resolveComponent as _resolveComponent } from "vue"
import _imports_0 from '@/assets/images/shake.png'


const _hoisted_1 = { class: "xz-select-page-box" }
const _hoisted_2 = {
  key: 0,
  class: "xz-select-desc"
}
const _hoisted_3 = {
  key: 1,
  class: "xz-select-desc"
}
const _hoisted_4 = { class: "xz-list-box" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "xz-name" }
const _hoisted_7 = { class: "xz-range" }
const _hoisted_8 = {
  key: 2,
  class: "fixed-shake"
}
const _hoisted_9 = { class: "native-box" }

import DisclaimerPopVue from '../common/DisclaimerPop.vue';
import IcpComp from '../common/IcpComp.vue';

import { Haa, OeReportType, OeReportSubType } from '@haa/fwk-h5/lib/index';
import bus from '@haa/fwk-h5/lib/bus';
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useParams } from '@/store/useParams';
import { useXz } from '@/hooks/useXz';

enum EventNams {
    Touchstart = 'touchstart',
    Click = 'click'
}

/** 默认使用touchstart触发点击 */

export default /*@__PURE__*/_defineComponent({
  __name: 'XzSelectPage',
  emits: ['change'],
  setup(__props, { emit: __emit }) {

const { oeReport } = Haa.util().useOeReport();

let hotEventName = ref(EventNams.Touchstart);
let notHotEventName = ref(EventNams.Touchstart);

const handleEventName = (type: number) => {
    // 0：全部touch_down触发跳转(默认);
    // 1：热点区域click触发，非热点区域touch_down触发跳转；
    const handleMap = [
        [EventNams.Touchstart, EventNams.Touchstart],
        [EventNams.Click, EventNams.Touchstart],
        [EventNams.Touchstart, EventNams.Click],
        [EventNams.Click, EventNams.Click],
    ]

    const [_hotEventName, _notHotEventName] = handleMap[type];

    _hotEventName && (hotEventName.value = _hotEventName);
    _notHotEventName && (notHotEventName.value = _notHotEventName);
}

const { params } = useParams();
const { active, defaultActive, xzList, handleChange } = useXz();

// 是否已触发跳转 网络不好时多次点击会触发多次上报
const isTriggerJump = ref(false);

const emitChange = __emit;
const handleClick = (index: number, e: any) => {
    let appX, appY;

    if (e) {
        appX = e.touches ? e.touches[0].clientX : e.clientX;
        appY = e.touches ? e.touches[0].clientY : e.clientY;
        appX = Math.round(appX);
        appY = Math.round(appY);
    }

    let item = xzList[index];

    // 避免网络延迟多次点击重复上报
    if (isTriggerJump.value) return;

    isTriggerJump.value = true;

    oeReport(OeReportType.Action, OeReportSubType.BtnClick, 'Guide', '选择星座:' + item.name, undefined, appX, appY);
    emitChange('change', index);
    handleChange(index);
}
const countdown = ref<number>(+params.skip || 0);
let dpTimer: any = null, strategyTimer: any = null;
if (countdown.value > 0) {
    dpTimer = setInterval(() => {
        countdown.value -= 1;
        if (countdown.value <= 0) {
            oeReport(OeReportType.Action, OeReportSubType.BtnClick, 'Guide', '倒计时默认星座');
            emitChange('change', defaultActive.value);
            handleChange(defaultActive.value);
            clearInterval(dpTimer);
            dpTimer = null;
        }
    }, 1000);
} else if (countdown.value == 0) {
    oeReport(OeReportType.Action, OeReportSubType.BtnClick, 'Guide', 'DP控制默认星座');
    emitChange('change', defaultActive.value);
    handleChange(defaultActive.value);
} else {

}

const { strategy } = Haa.util().useStrategy();
let globalClickable = ref<boolean>(false), timeout = ref<number>(-1);
const setGuideParams = () => {
    handleEventName(strategy.guide_used_click);

    if (strategy.guide_click_enable == 1) globalClickable.value = true;
    if (strategy.guide_timeout >= 0) timeout.value = strategy.guide_timeout;
    if (timeout.value > -1) {
        strategyTimer = setTimeout(() => {
            let random = Math.floor(Math.random() * 12);
            oeReport(OeReportType.Action, OeReportSubType.BtnClick, 'Guide', '策略控制随机星座');
            emitChange('change', random);
            handleChange(random);
        }, timeout.value * 1000);
    }
}
if (strategy.status != 0) {
    setGuideParams();
} else {
    bus.on('strategy', setGuideParams);
}

const handleTouch = (e: any) => {
    let appX, appY;

    if (e) {
        appX = e.touches ? e.touches[0].clientX : e.clientX;
        appY = e.touches ? e.touches[0].clientY : e.clientY;
        appX = Math.round(appX);
        appY = Math.round(appY);
    }

    if (!globalClickable.value) return;

    // 避免网络延迟多次点击重复上报
    if (isTriggerJump.value) return;

    isTriggerJump.value = true;


    oeReport(OeReportType.Action, OeReportSubType.BtnClick, 'Guide', 'touch非点击区域', undefined, appX, appY);
    emitChange('change', defaultActive.value);
    handleChange(defaultActive.value);
}
onUnmounted(() => {
    if (dpTimer) {
        clearInterval(dpTimer);
        dpTimer = null;
    }
    if (strategyTimer) {
        clearTimeout(strategyTimer);
        strategyTimer = null;
    }
})

const animateIndex = ref<number>(0);
onMounted(() => {
    setInterval(() => {
        animateIndex.value = (animateIndex.value + 1) % 12;
    }, 1000);
})


/** 31038上报 */
const onCriticalClick = (e: TouchEvent) => {
    let appX, appY;
    if (e) {
        appX = e.touches[0].clientX;
        appY = e.touches[0].clientY;
        appX = Math.round(appX);
        appY = Math.round(appY);
    }

    oeReport(OeReportType.Action, OeReportSubType.NonCriticalAreaClick, 'Guide', 'NonCriticalAreaClick', undefined, appX, appY);
}

const company = ref<string>(APP_CONFIG_COMPANY_NAME);

return (_ctx: any,_cache: any) => {
  const _component_wap_native = _resolveComponent("wap-native")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({ class: "xz-select-page-wrap" }, { [_toHandlerKey(_unref(notHotEventName))]: handleTouch }, { onTouchstart: onCriticalClick }), [
    _createElementVNode("div", _hoisted_1, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "xz-select-title" }, "选择您的星座", -1)),
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "xz-tips" }, "测试仅供娱乐", -1)),
      (countdown.value > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, "--" + _toDisplayString(countdown.value) + "s 后默认选择今天星座--", 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, "--默认选择今日星座--")),
      _createVNode(DisclaimerPopVue, { company: company.value }, null, 8, ["company"]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(xzList), (item, index) => {
          return (_openBlock(), _createElementBlock("div", _mergeProps({
            class: ["xz-item", {'active': _unref(active) == index, 'animate': animateIndex.value == index}]
          }, {
            [_toHandlerKey(_unref(hotEventName))]: _cache[0] || (_cache[0] = ($event: any) => (handleClick(animateIndex.value, $event)))
          }, {
            onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
            onTouchstart: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
          }), [
            _createElementVNode("img", {
              class: "avatar",
              src: item.avatar
            }, null, 8, _hoisted_5),
            _createElementVNode("div", _hoisted_6, _toDisplayString(item.name), 1),
            _createElementVNode("div", _hoisted_7, _toDisplayString(item.time), 1)
          ], 16))
        }), 256))
      ]),
      (_unref(timeout) > -1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[3] || (_cache[3] = [
            _createElementVNode("div", { class: "shake-text" }, "摇一摇随机选择", -1),
            _createElementVNode("img", { src: _imports_0 }, null, -1)
          ])))
        : (_openBlock(), _createBlock(IcpComp, { key: 3 }))
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_wap_native, { "space-key": "Guide_native" })
    ])
  ], 16))
}
}

})