const SPACE_DATA = {
		"u6995553":  [{space_js: "//swutws.cdymxikj.com/production/up/static/oxxm/openjs/m-m-h.js", space_src: "baidujs", space_type: "inters", space_id: "u6995553"}],
		"u6995554":  [{space_js: "//swutws.cdymxikj.com/common/vq/resource/p-yyn/common/n-nm.js", space_src: "baidujs", space_type: "native", space_id: "u6995554"}],
		"u6995555":  [{space_js: "//swutws.cdymxikj.com/common/wr-qz-zoo/source/oo.js", space_src: "baidujs", space_type: "native", space_id: "u6995555"}],
		"u6995556":  [{space_js: "//swutws.cdymxikj.com/common/x_sr_aap/pp/production/r.js", space_src: "baidujs", space_type: "native", space_id: "u6995556"}],
		"u6995557":  [{space_js: "//swutws.cdymxikj.com/source/yt/sb_bq/qq/openjs/u.js", space_src: "baidujs", space_type: "native", space_id: "u6995557"}],
};
export const SPACE_MAP = {
	Home_inter:  SPACE_DATA["u6995553"],
	Home_banner:  SPACE_DATA["u6995554"],
	Home_top:  SPACE_DATA["u6995555"],
	Home_center:  SPACE_DATA["u6995556"],
	Analysis_top:  SPACE_DATA["u6995555"],
	Analysis_center:  SPACE_DATA["u6995556"],
	Topic_top:  SPACE_DATA["u6995557"],
	FortuneDetail_day_top:  SPACE_DATA["u6995555"],
	FortuneDetail_week_top:  SPACE_DATA["u6995556"],
	FortuneDetail_month_top:  SPACE_DATA["u6995557"],
	AnalysisDetail_analysis_top:  SPACE_DATA["u6995555"],
	AnalysisDetail_analysis_center:  SPACE_DATA["u6995556"],
	AnalysisDetail_fortune_top:  SPACE_DATA["u6995557"],
	AnswerDetail_inter:  SPACE_DATA["u6995553"],
	AnswerDetail_banner:  SPACE_DATA["u6995554"],
	TopicList_top:  SPACE_DATA["u6995555"],
	TopicDetail_banner:  SPACE_DATA["u6995554"],
	TopicResult_native:  SPACE_DATA["u6995555"],
	TopicResult_banner:  SPACE_DATA["u6995554"],
}
export const ALL_SPACE_MAP = {
    H5_YMXZ_VO_10 : {
		Home_inter:  SPACE_DATA["u6995553"],
		Home_banner:  SPACE_DATA["u6995554"],
		Home_top:  SPACE_DATA["u6995555"],
		Home_center:  SPACE_DATA["u6995556"],
		Analysis_top:  SPACE_DATA["u6995555"],
		Analysis_center:  SPACE_DATA["u6995556"],
		Topic_top:  SPACE_DATA["u6995557"],
		FortuneDetail_day_top:  SPACE_DATA["u6995555"],
		FortuneDetail_week_top:  SPACE_DATA["u6995556"],
		FortuneDetail_month_top:  SPACE_DATA["u6995557"],
		AnalysisDetail_analysis_top:  SPACE_DATA["u6995555"],
		AnalysisDetail_analysis_center:  SPACE_DATA["u6995556"],
		AnalysisDetail_fortune_top:  SPACE_DATA["u6995557"],
		AnswerDetail_inter:  SPACE_DATA["u6995553"],
		AnswerDetail_banner:  SPACE_DATA["u6995554"],
		TopicList_top:  SPACE_DATA["u6995555"],
		TopicDetail_banner:  SPACE_DATA["u6995554"],
		TopicResult_native:  SPACE_DATA["u6995555"],
		TopicResult_banner:  SPACE_DATA["u6995554"],
    },
    DEFAULT : {
		Home_inter:  SPACE_DATA["u6995553"],
		Home_banner:  SPACE_DATA["u6995554"],
		Home_top:  SPACE_DATA["u6995555"],
		Home_center:  SPACE_DATA["u6995556"],
		Analysis_top:  SPACE_DATA["u6995555"],
		Analysis_center:  SPACE_DATA["u6995556"],
		Topic_top:  SPACE_DATA["u6995557"],
		FortuneDetail_day_top:  SPACE_DATA["u6995555"],
		FortuneDetail_week_top:  SPACE_DATA["u6995556"],
		FortuneDetail_month_top:  SPACE_DATA["u6995557"],
		AnalysisDetail_analysis_top:  SPACE_DATA["u6995555"],
		AnalysisDetail_analysis_center:  SPACE_DATA["u6995556"],
		AnalysisDetail_fortune_top:  SPACE_DATA["u6995557"],
		AnswerDetail_inter:  SPACE_DATA["u6995553"],
		AnswerDetail_banner:  SPACE_DATA["u6995554"],
		TopicList_top:  SPACE_DATA["u6995555"],
		TopicDetail_banner:  SPACE_DATA["u6995554"],
		TopicResult_native:  SPACE_DATA["u6995555"],
		TopicResult_banner:  SPACE_DATA["u6995554"],
    },
};
