<script setup lang="ts">
import DisclaimerPop from "./DisclaimerPop.vue";

import { ref } from "vue";
const appName = ref<string>(APP_NAME);
const company = ref<string>(APP_CONFIG_COMPANY_NAME);
const icp = ref<string>(APP_CONFIG_BEI_AN_CODE);
</script>

<template>
  <div class="icp-wrapper">
    <p class="text">ICP备案/许可证号：{{ icp }}</p>
    <p class="text">{{ appName }}-{{ company }}</p>
    <DisclaimerPop :company="company"></DisclaimerPop>
  </div>
</template>

<style scoped lang="less">
.icp-wrapper {
    text-align: center;
    margin: 30px 0;
    font-size: 26px;
    line-height: 30px;
    color: inherit;
    p{
        margin: 0;
    }
    p:nth-of-type(1) {
        margin-bottom: 20px;
    }
}
</style>
