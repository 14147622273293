import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "main-tab-wrapper" }
const _hoisted_2 = { class: "main-tab-box" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]

import { ref, reactive, watch } from 'vue';
import { useRoute } from 'vue-router';

import { Haa, OeReportType, OeReportSubType } from '@haa/fwk-h5/lib/index';

export default /*@__PURE__*/_defineComponent({
  __name: 'MainTab',
  emits: ['change'],
  setup(__props, { emit: __emit }) {

const { oeReport } = Haa.util().useOeReport();

const active = ref<number>(0);
const route = useRoute();
watch(
    () => route.path,
    () => {
        tabList.forEach((item, index) => {
            if (item.path == route.path) active.value = index;
        })
    }
);
const emitChange = __emit;
const handleTabChange = function (index: number) {
    active.value = index;
    oeReport(OeReportType.Action, OeReportSubType.TabClick, tabList[active.value].text);
    emitChange('change', active.value);
}

const appName = APP_NAME;
const tabList = reactive([
    { icon: require('@/assets/images/tab_1.png'), text: appName, path: '/home' },
    { icon: require('@/assets/images/tab_2.png'), text: '星座解读', path: '/analysis' },
    { icon: require('@/assets/images/tab_3.png'), text: '答案之书', path: '/answer' },
    { icon: require('@/assets/images/tab_4.png'), text: '测一测', path: '/topic' },
])
tabList.forEach((item, index) => {
    if (item.path == route.path) active.value = index;
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabList, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["tab-item", {'active': active.value == index}]),
          onClick: ($event: any) => (handleTabChange(index))
        }, [
          _createElementVNode("img", {
            src: item.icon
          }, null, 8, _hoisted_4),
          _createElementVNode("span", null, _toDisplayString(item.text), 1)
        ], 10, _hoisted_3))
      }), 256))
    ])
  ]))
}
}

})