import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/images/icon.png'


const _hoisted_1 = { class: "home-view-wrapper" }
const _hoisted_2 = { class: "main-title" }

import MainTab from "@/components/MainTab.vue";

import { reactive } from 'vue';
import { useRouter } from 'vue-router';

import { Haa } from '@haa/fwk-h5/lib/index';
import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

Haa.util().useLifeCycle();

const showBottomNative = ref(false);

const router = useRouter();
const appName = ref<string>(APP_NAME);
const tabList = reactive([
    { icon: require('@/assets/images/tab_1.png'), text: appName.value, path: '/home' },
    { icon: require('@/assets/images/tab_2.png'), text: '星座解读', path: '/analysis' },
    { icon: require('@/assets/images/tab_3.png'), text: '答案之书', path: '/answer' },
    { icon: require('@/assets/images/tab_4.png'), text: '测一测', path: '/topic' },
])
const handleTabChange = function (value: number) {
    let tab = tabList[value];
    router.replace({ path: tab.path });

    showBottomNative.value = false;
}


const handleScroll = (e: any) => {
    let scrollTop = e.target.scrollTop;
    if (scrollTop > 500) {
        showBottomNative.value = true;
    }
}


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_wap_banner = _resolveComponent("wap-banner")!
  const _directive_slide = _resolveDirective("slide")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
      _createElementVNode("span", null, _toDisplayString(appName.value), 1)
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: "page-box",
      onScroll: handleScroll
    }, [
      _createVNode(MainTab, { onChange: handleTabChange }),
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { "show-bottom-native": showBottomNative.value }, null, 8, ["show-bottom-native"]))
        ]),
        _: 1
      })
    ], 32)), [
      [_directive_slide]
    ]),
    _createVNode(_component_wap_banner, { "space-key": "Home_banner" })
  ]))
}
}

})