import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "xz-select-wrapper" }

import XzSelectPage from '@/components/xz/XzSelectPage.vue';
import Guide1 from '@/components/guide/guide1.vue';
import Guide2 from '@/components/guide/guide2.vue';

import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { Haa } from '@haa/fwk-h5/lib/index';

export default /*@__PURE__*/_defineComponent({
  __name: 'XzSelect',
  setup(__props) {

const random = ref(Math.floor(Math.random() * 3));

Haa.util().useLifeCycle();
const { openWindow } = Haa.util().useOeReport();

const router = useRouter();
const handleConfirm = () => {
    let result = openWindow({ skip: '0' });
    if (!result) router.replace({ path: '/home' });
}

return (_ctx: any,_cache: any) => {
  const _component_wap_banner = _resolveComponent("wap-banner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(XzSelectPage, { onChange: handleConfirm }),
    _createVNode(_component_wap_banner, { "space-key": "Guide_banner" })
  ]))
}
}

})